<template>
  <div id="inside">
    <div class="container" v-if="!loading">
      <div class="row">
        <div
          class="
            col-12
            flex-column
            px-2
            pb-4
            d-flex
            order-1 order-lg-3
            justify-content-center
            align-items-center
            col-lg-4
          "
          style=""
        >
          <div class="btn-group btn-group-sm">
            <button
              class="
                btn btn-link
                d-flex
                flex-column
                justify-content-left
                text-decoration-none
              "
              @click="contractsPending()"
            >
              <i class="fa fa-edit fa-fw"></i> contratos
            </button>
            <button
              class="
                btn btn-link
                d-flex
                flex-column
                justify-content-left
                text-decoration-none
              "
              @click="verBoletos()"
            >
              <i class="fa fa-fw fa-file-text-o"></i> meus acordos
            </button>
            <button
              class="
                btn btn-link
                d-flex
                flex-column
                justify-content-left
                text-decoration-none
              "
              @click="cadastro()"
            >
              <i class="fa fa-fw fa-user-o"></i>cadastro</button
            ><button
              class="
                btn btn-link
                d-flex
                flex-column
                justify-content-left
                text-decoration-none
              "
              @click="cadastro()"
            >
              <i class="fa fa-fw fa-key"></i>código de Acesso
            </button>
          </div>
        </div>
        <div class="col-12 order-1 order-lg-2 col-lg-5" style="">
          <h5 class="text-body mb-1">Olá, {{ user.FullName }}</h5>
          <p class="text-info mb-3" v-if="user.DocNumber.length < 14">
            CPF - {{ formatDocument(user.DocNumber).split('.')[0].substring(0, 6) + '.XXX' + '.' + 'XXX-' + formatDocument(user.DocNumber).split('-')[1] }}
            <!-- e.split('@')[0].substring(0,4) + 'xxxxxxxxxx' + '@' + e.split('@')[1] -->
          </p>
          <p class="text-info mb-3" v-if="user.DocNumber.length > 11">
            CNPJ -
            {{
              user.DocNumber.replace(
                /^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/,
                "$1.$2.$3/$4-$5"
              ).split('.')[0].substring(0, 6) + '.XXX' + '.' + 'XXX' + '/' + user.DocNumber.replace(
                /^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/,
                "$1.$2.$3/$4-$5"
              ).split('/')[1]
            }}
          </p>
          <p class="mb-2 text-info">
            Consulte contratos, ofertas e condições para negociar seus débitos.
          </p>
        </div>

        

        <div class="col-12 text-body order-1 order-lg-2 col-lg-3 pr-0" style="">
          <h6 class="mt-2 small" v-if="hasDebts && !loading">
            Você tem ({{ sumOpenContracts() }}) contrato(s) em aberto
            <span class="badge badge-pill badge-dark">!</span>
          </h6>
          <!-- <h6 class="mt-2 small" v-if="!contracts || typeof contracts.debts === 'undefined' && !loading">
            Você não tem contrato(s) em aberto
            <span class="badge badge-pill badge-dark">!</span>
          </h6> -->
          <p
            class="m-0 small text-info"
            v-if="hasDebts && !loading"
          >
            Pendências totais:
          </p>
          <h5
            class="text-danger"
            v-if="hasDebts && !loading"
            style=""
          >
            {{ sumByContract() }}
          </h5>
        </div>
      </div>
      <div class="container pt-5" v-if="!hasDebts && !loading">
          <h4>Você não possui contratos a serem negociados.</h4> 
        </div>
    </div>
    <div class="container" v-if="!loading">
      <div class="row">
        <div class="col-lg-12">
          <div class="row">
            <div class="col-12 p-3">
              <h4 v-if="hasDebts && !loading">
                Contratos em aberto
              </h4>
            </div>
          </div>
          <div v-for="(value1, key1, index1) in contracts"
            :key="`${key1}-${index1}`">
            <!-- <pre>{{value1.debts}}</pre> -->

          <div
            class="card mb-5"
            v-for="(value, key, index) in value1.debts"
            :key="`${key}-${index}`"
          >
            <div class="card-header" style="">
              <i class="fa fa-fw mr-2 text-secondary fa-file-text-o"></i
              > Contrato {{ value.NumeroTitulo.substring(0, 3) + 'XXXXXX' +  value.NumeroTitulo.substr(-3) }}
            </div>
            <div class="card-body pb-2">
              <div class="row mb-3">
                <div class="col-md-9 col-10">
                  <h6 class="text-primary mb-1">{{ value.Produto }}</h6>
                </div>
              </div>
              <div class="row">
                <div class="col-md-12 col-12 mx-0 px-0">
                  <div
                    class="table-responsive"
                    :style="
                      !isExpanded[value.NumeroTitulo].isExp
                        ? cssHeight(value)
                        : ''
                    "
                  >
                    <table class="table table-hover table-striped">
                      <thead class="small">
                        <tr>
                          <th scope="col">Código Parcela</th>
                          <th scope="col">Data vencimento</th>
                          <th scope="col">Valor débito</th>
                          <th scope="col">Status</th>
                        </tr>
                      </thead>
                      <tbody class="small">
                        <tr
                          v-for="(installments, key1, index1) in value.Parcelas
                            .ParcelaDivida"
                          :key="`${key1}-${index1}`"
                        >
                          <td>{{ installments.CodigoParcela }}</td>
                          <td>{{ installments.Vencimento }}</td>
                          <th scope="row">
                            R$
                            {{
                              parseFloat(
                                installments.Valor.replace(",", "."),
                                10
                              ).toLocaleString("pt-BR", {
                                currency: "BRL",
                                minimumFractionDigits: 2,
                              })
                            }}
                          </th>
                          <td
                            v-if="compareDates(installments.Vencimento)"
                            class="text-danger"
                          >
                            Vencido
                          </td>
                          <td v-else class="text-warning">Pendente</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
            <div
              class="d-flex justify-content-between align-items-center mt-3"
              style=""
            >
              <button
                v-if="!loading"
                class="btn btn-lg btn-primary mb-4"
                @click="expandir(value.NumeroTitulo)"
                :style="
                  !isExpanded[value.NumeroTitulo].showExp
                    ? ''
                    : 'display: none;'
                "
              >
                {{ isExpanded[value.NumeroTitulo].value }}
              </button>
              <button
                v-if="!loading"
                class="
                  btn btn-lg btn-primary btn-sm
                  float-right
                  mb-4
                  text-white
                "
                @click="propostas(value, value1.OwnerID)"
              >
                Quero pagar
              </button>
            </div>
          </div>
          </div>

          <div class="row">
          <div class="" v-for="(value1, key1, index1) in contractsCetelem"
            :key="`Cetelem-${key1}-${index1}`">
          <div
            class="card mb-5 mx-5 col-4"
            v-for="(value, key, index) in value1.debts"
            :key="`Cetelem-${key}-${index}`"
          >
            <div class="card-header" style="">
              <i class="fa fa-fw mr-2 text-secondary fa-file-text-o"></i
              > Contrato {{ value.contractNumber.substring(0, 3) + 'XXXXXX' +  value.contractNumber.substr(-3) }}
            </div>
            <div class="card-body pb-2">
              <div class="row mb-3">
                <div class="col-md-6 col-10">
                  <h6 class="mb-1"> Valor total:<br/> <span class="text-danger">{{ value.updatedValue }}</span></h6>
                </div>
                <div class="col-md-6 col-10">
                  <h6 class="mb-1"> Vencimento:<br/> {{ formatData(value.dueDate, "YYYY-MM-DD", "DD/MM/YYYY") }}</h6>
                </div>
                <div v-if="value.daysDelay != 0" class="col-md-9 col-10">
                  <h6 class="mt-3 mb-1"> Dias de atraso:<br/> {{ value.daysDelay }}</h6>
                </div>
              </div>
            </div>
            <div
              class="d-flex justify-content-center align-items-center mt-3"
              style=""
            >
              <button
                v-if="!loading"
                class="
                  btn btn-lg btn-primary btn-sm
                  float-right
                  mb-4
                  text-white
                "
                @click="propostas(value, value1.OwnerID)"
              >
                Negociar
              </button>
            </div>
          </div>
          </div>

          <!-- contrato fechado -->
          <div>
          <div
            class="card mb-5 col-4"
            v-for="(value, key, index) in contractClosed"
            :key="`Cetelem-${key}-${index}`"
          >
            <div class="card-header" style="">
              <i class="fa fa-fw mr-2 text-secondary fa-file-text-o"></i
              > Contrato {{ value.contractNumber.substring(0, 3) + 'XXXXXX' +  value.contractNumber.substr(-3) }}
            </div>
            <div class="card-body pb-2">
              <div class="row mb-3">
                <div class="col-md-6 col-10">
                  <h6 class="mb-2"> Valor Negociado:<br/> <span class="text-danger">{{ value.updatedValue }}</span></h6>
                </div>
                <div class="col-md-6 col-10">
                  <h6 class="mb-2"> Total de parcelas:<br/> <span class="text-danger">{{ value.installments.length }}</span></h6>
                </div>
                <div class="col-md-6 col-10">
                  <h6 class="mb-1"> Parcela em aberto:<br/> {{ value.installments.length }}</h6>
                </div>
                <div class="col-md-6 col-10">
                  <h6 class="mb-1"> Vencimento:<br/> {{ formatData(value.dueDate, "YYYY-MM-DD", "DD/MM/YYYY") }}</h6>
                </div>
                <div v-if="value.daysDelay != 0" class="col-md-9 col-10">
                  <h6 class="mt-3 mb-1"> Dias de atraso:<br/> {{ value.daysDelay }}</h6>
                </div>
              </div>
            </div>
            <div
              class="d-flex justify-content-center align-items-center mt-3"
              style=""
            >
              <button
                v-if="!loading"
                class="
                  btn btn-lg btn-primary btn-sm
                  float-right
                  mb-4
                  text-white
                "
                @click="verBoletos(value, value.OwnerID)"
              >
                Ver boletos
              </button>
            </div>
          </div>
        </div>
      </div>

        </div>
      </div>
      <div class="row" style=""></div>
    </div>
    <div class="container">
      <ProgressLoading v-bind:loading="loading" />
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import {
  formatCurrencieValueBRL as formatValue,
  formatData,
  formatDocument,
} from "@/utils/helpers";
import { mapState } from "vuex";
import ProgressLoading from "../../modules/auth/components/ProgressLoading.vue";

export default {
  name: "Dashboard",
  components: {
    ProgressLoading,
  },
  data() {
    return {
      loadDividas: false,
      loading: false,
      isExpanded: {},
      totalDivida: 0,
      containerClass: "container-fluid",
      colPageClass:
        "d-inline-flex flex-wrap flex-column col-lg-4 col-md-4 col-acao",
      colTrocarCodigoClass: "col-12 col-md-2 text-right",
      links: [],
      typesProofPayment: [],
      contracts: [],
      contractsCetelem: [],
      contractClosed: [],
      dataAtual: null,
      largura: null,
      hasDebts: false,
      userAdm: {},
      ipUser: null
    };
  },
  async created() {
    this.userAdm = JSON.parse(localStorage.getItem("userAdm"));
    this.ipUser = localStorage.getItem('ipUser');
    localStorage.removeItem('contracts');
    localStorage.removeItem('contractsCetelem');
    localStorage.removeItem('boletoSelected');
    localStorage.removeItem('OwnerID');
    localStorage.removeItem('info');
    this.largura = window.screen.width;
    this.loading = true;

    this.contractClosed = JSON.parse(localStorage.getItem("contractClosed"))

    const respContract = await this.ActionGetDebtsClaro({ 
        UserID: this.userAdm ? this.userAdm.UserID : null,
        UserFullName: this.userAdm ? this.userAdm.UserFullName : null,
        OS: navigator.platform,
        IP: this.ipUser ? this.ipUser : '',
      });

    respContract.forEach(e => {
      if(e.OwnerID != 34){
        this.contracts.push(e)
      }else{
        this.contractsCetelem.push(e)
      }
    });

    // this.contracts = respContract || {};
    
    if(this.contracts.length < 1) {
      this.loading = false;
      return;
    }
    //eslint-disable-next-line
      // debugger;
      
    if(this.contracts) {
      localStorage.setItem("contracts", JSON.stringify(this.contracts));
    } else {
      this.contracts = [];
    }

    if(this.contractsCetelem) {
      localStorage.setItem("contractsCetelem", JSON.stringify(this.contractsCetelem));
    } else {
      this.contractsCetelem = [];
    }

    if(this.contracts) {
      this.hasDebts = false;
      this.contracts.map((element) => {
        if(element.debts.length > 0) {
          this.hasDebts = true;
        }
      });
    }

    if(this.contractsCetelem) {
      this.hasDebts = false;
      this.contractsCetelem.map((element) => {
        if(element.debts.length > 0) {
          this.hasDebts = true;
        }
      });
    }

    if (!this.hasDebts) {
          this.verBoletos();
    } 

    if (this.contracts) {
      this.contracts.map((value1) => {
        value1.debts.map((value) => {
          this.isExpanded[value.NumeroTitulo] = {
            isExp: false,
          value: "Expandir",
        };
        })
      });
    }
    if (respContract.status == 500) {
      this.loading = false;

      this.$router.push({
        name: "erro",
      });
    }



    this.loading = false;
  },
  methods: {
    formatValue,
    formatData,
    formatDocument,
    ...mapActions("meusContratos", ["ActionGetDebtsClaro", "ActionInsertValuesContract", "ActionGetInstallmentsClaro"]),

    cssHeight(value) {
      if (value.Parcelas.ParcelaDivida.length > 5) {
        return "height: 18.2rem; overflow-y: auto;";
      } else {
        this.isExpanded[value.NumeroTitulo].showExp = true;
        return `height: ${
          value.Parcelas.ParcelaDivida.length * 2.2 + 4.5
        }rem; overflow-y: auto;`;
      }
    },

    compareDates(date) {
      let parts = date.split("/");
      let today = new Date();

      date = new Date(parts[2], parts[1] - 1, parts[0]);

      return today > date ? true : false;
    },

    sumByContract() {
      let total = 0;
      // if(this.contracts) {
      //     this.contracts.map((value1) => {
      //       value1.debts.map((value) => {
      //         value.Parcelas.ParcelaDivida.map((installments) => {
      //           total += parseFloat(
      //             installments.Valor.replace(".", "").replace(",", ".")
      //           );
      //         });
      //       })
            
      //     });
      //     return total
      //       .toLocaleString("pt-BR", { style: "currency", currency: "BRL" })
      //       .replace("-", "");
      // } else {
      //   return 0;
      // }

      if(this.contractsCetelem) {
          this.contractsCetelem.map((value1) => {
            value1.debts.map((value) => {
                total += parseFloat(
                  value.updatedValue
                );
            })
            
          });
          return total
            .toLocaleString("pt-BR", { style: "currency", currency: "BRL" })
            .replace("-", "");
      } else {
        return 0;
      }
      
    },

    sumOpenContracts() {
      var totalOpenContracts = 0;
        if (this.contracts) {
          this.contracts.map((contract) => {
            contract.debts.map((item) => {
                  item;
                  totalOpenContracts += 1;
            })
          });
      }
      if (this.contractsCetelem) {
          this.contractsCetelem.map((contract) => {
            contract.debts.map((item) => {
                  item;
                  totalOpenContracts += 1;
            })
          });
      }
      return totalOpenContracts;
    },

    async expandir(contrato) {
      this.isExpanded[contrato].isExp = !this.isExpanded[contrato].isExp;
      this.isExpanded[contrato].value =
        this.isExpanded[contrato].value === "Expandir"
          ? "Esconder"
          : "Expandir";
      this.$forceUpdate();
    },

    async negociar(value) {
      localStorage.setItem("contracts", JSON.stringify(value));
      this.$router.push({
        name: "negociarDivida",
        params: { contracts: value },
      });
    },

    async propostas(value, ownerId) {
      this.loading = true;
      value.OwnerID = ownerId;
      localStorage.setItem("contractSelected", JSON.stringify(value));

      var resp = null

      try {
        
        if(ownerId == 34){
          this.loading = false;
          this.$router.push({
          name: "propostas",
          params: { noReload: true },
        });
          
        } else{
          resp = await this.ActionInsertValuesContract({
            DocNumber: this.user.DocNumber,
            ContractNumber: value.NumeroTitulo + '/' + value.Produto,
            JsonValue: JSON.stringify(value.Parcelas.ParcelaDivida), 
            httpOwnerId: ownerId,
            UserID: this.userAdm ? this.userAdm.UserID : null,
            UserFullName: this.userAdm ? this.userAdm.UserFullName : null,
            IP: this.ipUser ? this.ipUser : '',
            OS: navigator.platform,
        })
        }

      if(resp) {
        this.loading = false;
        this.$router.push({
          name: "propostas",
          params: { noReload: true },
        });
      }


      } catch (error) {
        this.flashMessage.show({
              timeout: 1000,
              status: "error",
              title: "Error",
              message: error.message,
            });
            this.loading = false;
      }
    },

    async verBoletos() {
      this.loading = true
      // const resp = await this.ActionGetInstallmentsClaro();
      this.$router.push({
        name: "meusBoletos",
        // params: { responseFromAgreement: resp },
      });
      // return resp;
    },

    contractsPending() {
      this.$router.push({
        name: "meusContratos",
      });
    },

    cadastro() {
      this.$router.push({
        name: "cadastro",
      });
    },

    verAcordos() {
      this.$router.push({
        name: "meusAcordos",
      });
    },

    async copiarLinhaDigitavel(linhaDigitavel) {
      const elem = document.createElement("textarea");
      elem.value = linhaDigitavel;
      document.body.appendChild(elem);
      elem.select();
      document.execCommand("copy");
      document.body.removeChild(elem);

      this.loadBoletos = true;

      await this.ActionSendAccessLog({
        ValueId: 11,
        LinhaDigitavel: linhaDigitavel,
      });

      this.loadBoletos = false;

      this.flashMessage.show({
        timeout: 1000,
        status: "success",
        title: "Sucesso",
        blockClass: "custom-block-class",
        message: "Linha digitável foi copiada para o seu clipboard",
      });
    },
  },

  computed: {
    ...mapState("auth", ["user"]),
    isLoggedIn() {
      return this.$store.getters["auth/isLoggedIn"];
    },
  },
};
</script>
<style>
.navbar {
  padding-bottom: 14px;
  padding-top: 15px;
  background-color: #fff;
  color: #333;
}
</style>
